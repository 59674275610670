(function(){

	var module = 'search';
	var $body = $('html, body');
	var $form = $('#searchForm');
	var $containerBar = $('#cd-search');
	var $input_search = $containerBar.find('input[type="search"]');

	setSearch();
	resultSearch();

	function setSearch(){
		$form.on('submit', cancelEvent);
		$input_search.on('keypress', sendSearch);
	};

	function cancelEvent(event){
		event.preventDefault();
	};

	function sendSearch(event){
		var input_value = $(this).val();
		if(event.which == 13) {
	    	if (input_value.length) {
	    		window.location.href = window.location.origin + '/search?term=' + input_value;
	    	}
		}
	};

	function resultSearch(){
		var $result = $('.listados');
		var $result_string = $('.listados').find('.buscado');
		$result_string.html('"' + window.location.href.split('=')[1] + '"');
	};

})();